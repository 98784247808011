var safari = navigator.userAgent.indexOf('Safari') > -1;

function ajaxifyForm(event) {
	var form = event.target;
	var data = new FormData(form);
	var request = new XMLHttpRequest();

	if (safari) {
		for (var i = 0; i < form.elements.length; i++) {
			if (form.elements[i].type == 'file') {
				if (form.elements[i].value == '') {
					var elem = form.elements[i];
					data.delete(elem.name);
				}
			}
		}
	}

	var method = form.getAttribute('method');
	var action = form.getAttribute('action');
	var isApplicationForm = form.querySelector('#ApplicationForm');

	request.open(method, action ? action : window.location.href, true);
	request.setRequestHeader('Cache-Control', 'no-cache');
	request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
	request.setRequestHeader('HTTP_X_REQUESTED_WITH', 'XMLHttpRequest');
	request.onload = function () {
		removeMessages(form);
		if (request.status === 200) {
			var response = JSON.parse(request.response);

			if (response.success) {
				// Reset the form so that the user may enter fresh information
				form.reset();

				// ============================================================
				// Uncomment this to have the form redirect to the success page
				// ============================================================
				// if (response.return) {
				//   window.location.href = response.return;
				// }

				if (isApplicationForm != null) {
					renderApplicationFormSuccess(form);
				} else {
					renderFormSuccess(form);
				}
			} else if (response.errors || response.formErrors) {
				renderErrors(
					convertErrorMessages(response.errors, response.formErrors),
					form,
				);
				renderFormErrors(
					convertFormErrorMessages(response.formErrors),
					form,
				);
			}
			replaceInputs(form, response);
			unlockSubmit(form);
		} else {
			console.error(request);
		}

		unlockSubmit(form);
	};

	request.send(data);
	event.preventDefault();
}

function replaceInputs(form, response) {
	if (response.duplicateCheck) {
		var prefix = response.duplicateCheck.prefix;
		var value = response.duplicateCheck.value;
		var input = form.querySelector('input[name^="' + prefix + '"]');
		if (input) {
			input.setAttribute('name', value);
			input.setAttribute('value', value);
		}
	}
}

/**
 * Remove the "disabled" state of the submit button upon successful submit
 *
 * @property form
 */
function unlockSubmit(form) {
	form.querySelector('button[type=submit]').removeAttribute('disabled');
	if (typeof grecaptcha !== 'undefined') {
		grecaptcha.reset();
	}
}

function removeMessages(form) {
	// Remove error messages
	form.querySelectorAll('ul.errors').remove();
	var fieldsWithErrors = form.querySelectorAll('.has-error');
	for (
		var fieldIndex = 0;
		fieldIndex < fieldsWithErrors.length;
		fieldIndex++
	) {
		fieldsWithErrors[fieldIndex].classList.remove('has-error');
	}

	// Remove success messages
	form.querySelectorAll('.form-success').remove();
	document.getElementsByClassName('form-errors').remove();
}

function renderFormSuccess(form) {
	var successMessage = document.createElement('div');
	successMessage.classList.add('alert', 'alert-success', 'form-success');

	successMessage.appendChild(
		document.createTextNode('Form has been submitted successfully!'),
	);
	const messageBox = form.querySelector('.ajax-form__message');
	console.log(messageBox);
	messageBox.innerHTML = '';
	messageBox.appendChild(successMessage);
}

function renderApplicationFormSuccess(form) {
	var successMessage = document.createElement('div');
	successMessage.classList.add('alert', 'alert-success', 'form-success');

	successMessage.appendChild(
		document.createTextNode(
			'Form has been submitted successfully! Thank you for your interest in a career with Hood Sweeney. A member of the People & Culture team will be in contact with you if you are successful in moving to the next stage.',
		),
	);
	const messageBox = form.querySelector('.ajax-form__message');
	console.log(messageBox);
	messageBox.innerHTML = '';
	messageBox.appendChild(successMessage);
}

function renderErrors(errors, form) {
	for (var key in errors) {
		if (!errors.hasOwnProperty(key) || !key) {
			continue;
		}
		var messages = errors[key];
		var errorsList = document.createElement('ul');
		errorsList.classList.add('errors');

		for (
			var messageIndex = 0;
			messageIndex < messages.length;
			messageIndex++
		) {
			var message = messages[messageIndex];
			var listItem = document.createElement('li');
			listItem.appendChild(document.createTextNode(message));
			errorsList.appendChild(listItem);
		}
		var inputList = form.querySelectorAll(
			'*[name=' +
				key +
				"], *[data-name='" +
				key +
				"'], *[name='" +
				key +
				"[]']",
		);
		for (var inputIndex = 0; inputIndex < inputList.length; inputIndex++) {
			var input = inputList[inputIndex];

			input.findAncestor('form__field').classList.add('has-error');
			input.findAncestor('form__field').appendChild(errorsList);
		}
	}
}

function renderFormErrors(errors, form) {
	var errorBlock = document.createElement('div');

	if (errors.length) {
		var errorsList = document.createElement('ul');
		for (
			var messageIndex = 0;
			messageIndex < errors.length;
			messageIndex++
		) {
			var message = errors[messageIndex];
			var listItem = document.createElement('li');

			listItem.appendChild(document.createTextNode(message));
			errorsList.appendChild(listItem);
		}

		errorBlock.appendChild(errorsList);
	}
	const messageBox = form.querySelector('.ajax-form__message');
	console.log(messageBox);
	messageBox.innerHTML = '';
	messageBox.appendChild(errorBlock);
}

// Add remove prototypes
Element.prototype.remove = function () {
	this.parentElement.removeChild(this);
};

Element.prototype.findAncestor = function (className) {
	let el = this;
	while (!el.classList.contains(className)) {
		el = el.parentElement;
	}

	return el;
};

NodeList.prototype.remove = HTMLCollection.prototype.remove = function () {
	for (var i = this.length - 1; i >= 0; i--) {
		if (this[i] && this[i].parentElement) {
			this[i].parentElement.removeChild(this[i]);
		}
	}
};
if (document.getElementById('ajax-form')) {
	document
		.getElementById('ajax-form')
		.addEventListener('submit', ajaxifyForm);
}

// Per site -> change errro message, especially for the required fields
function convertErrorMessages(errors, formErrors) {
	if (!errors) return errors;
	for (var key in errors) {
		if (!errors.hasOwnProperty(key) || !key) {
			continue;
		}
		var messages = errors[key];
		for (
			var messageIndex = 0;
			messageIndex < messages.length;
			messageIndex++
		) {
			var message = messages[messageIndex];

			if (message.indexOf('is required') > 0) {
				if (key == 'name')
					messages[messageIndex] = 'Please enter your name.';
				else if (key == 'email')
					messages[messageIndex] =
						'A valid email address is required.';
				else if (key == 'message')
					messages[messageIndex] = "You haven't typed a message yet.";
			}
		}
	}

	if (formErrors) {
		for (
			var messageIndex = 0;
			messageIndex < formErrors.length;
			messageIndex++
		) {
			var message = formErrors[messageIndex];
			if (message.indexOf('you are not a robot') > 0) {
				errors['recaptcha'] = [message];
			}
		}
	}

	console.log(errors);
	return errors;
}

function convertFormErrorMessages(errors) {
	const filtered = [];
	for (var messageIndex = 0; messageIndex < errors.length; messageIndex++) {
		var message = errors[messageIndex];
		if (message.indexOf('you are not a robot') > 0) continue;
		filtered.push(message);
	}
	return filtered;
}
